<template>
  <p
    @click="$emit('click')"
    :class="{ full: ifFullDescription }"
    class="operator-description">
    <Icon :path="mdiTruck" :size="14" />
    <span>
      {{ ifFullDescription ? text : "Info" }}
      <span class="toggle" v-if="showToggle && ifFullDescription">Hide</span>
    </span>
  </p>

  <div
    class="description"
    :class="[
      {
        long: ifVendorDescriptionTooLong,
      },
      { trucated: ifVendorDescriptionTrucated },
    ]">
    <p ref="vendor-description">
      {{ service?.vendor.description }}
    </p>
    <span
      class="more"
      @click="this.toggleVendorDescription"
      v-if="ifVendorDescriptionTooLong">
      {{ ifVendorDescriptionTrucated ? "more" : "Show less" }}</span
    >
  </div>
</template>

<script>
  import { mdiTruck } from "@mdi/js";
  export default {
    data() {
      return { mdiTruck };
    },

    props: {
      text: {
        type: String,
      },
      showToggle: { type: Boolean, default: false },
      ifFullDescription: {
        type: Boolean,
        default: false,
      },
    },
    emits: ["click"],
  };
</script>
<style lang="scss" scoped>
  .operator-description {
    @include outlet-list-clickable-info;
  }
</style>
