<template>
  <header
    :class="{ mini: showMaxMenuSection }"
    ref="header"
    :style="{
      'min-height':
        mobileLayout && headerHeight !== 0 ? headerHeight + 'px' : null,
    }">
    <div class="header-image">
      <ImageCarousel
        :assets="getImgCdnUrls(operator.thumbnails)"
        :alt="operator?.name" />
    </div>

    <div class="header">
      <div class="details">
        <h1 class="operator-name">{{ operator.name }}</h1>
        <p
          v-if="showMaxMenuSection"
          @click="showDescription = !showDescription"
          class="operator-description">
          <Icon :path="mdiTruck" :size="14" />Info
        </p>

        <TrucatableDescription
          v-show="operator.description && !showMaxMenuSection"
          :description="operator.description" />
      </div>

      <Description
        v-if="showMaxMenuSection && showDescription"
        if-fullDescription
        show-toggle
        :text="operator.description"
        @click="showDescription = false" />

      <!-- TODO: Add some kinda day picker  that updates which services you can see, not clear how to use space effectively  -->

      <div class="selector" v-if="showDatePicker">
        <template v-if="getDateOptions.length > 0">
          <SelectorDay
            v-for="option of getDateOptions"
            :key="option"
            :current="currentDate === option"
            :option="option"
            @click="$emit('selectDate', option)" />
        </template>
        <NoDataText
          v-else
          minHeight="40px"
          width="fit-content"
          text="No Upcoming Services" />
      </div>
    </div>
  </header>
</template>

<script>
  import Enumerable from "linq";
  import { mdiMapMarker, mdiCalendar, mdiTruck } from "@mdi/js";
  import NoDataText from "@/components/NoDataText";
  import ImageCarousel from "@/components/Images/ImageCarousel.vue";
  import SelectorDay from "@/components/ListHeader/SelectorDay.vue";
  import Description from "@/components/ListHeader/Description.vue";
  import TrucatableDescription from "@/components/Vendor/TrucatableDescription.vue";

  export default {
    data() {
      return {
        mdiMapMarker,
        mdiCalendar,
        mdiTruck,
        showFullAddress: false,
        scrollPosition: 0,
        showDatePicker: true,
        showDescription: false,
        showMaxMenuSection: false,
        datePickerTimestamp: Date.now(),
        headerHeight: 0,
        ifVendorDescriptionTrucated: false,
        ifVendorDescriptionTooLong: false,
      };
    },
    props: {
      mobileLayout: { type: Boolean },
      tabletLayout: { type: Boolean },
      operator: { type: Object },
      services: { type: Array },
      currentDate: { type: String },
    },
    components: {
      NoDataText,
      ImageCarousel,
      SelectorDay,
      Description,
      TrucatableDescription,
    },
    computed: {
      getDateOptions() {
        return Enumerable.from(this.services)
          .select((x) => x.startDate)
          .distinct()
          .orderBy((x) => x)
          .toArray();
      },
    },
    emits: ["selectDate"],
    watch: {
      scrollPosition(newValue, preValue) {
        this.datePickerTimestamp = Date.now();
        // Scrolling down after the header is hidden: Show fixed bar
        if (newValue >= this.headerHeight && newValue > preValue) {
          this.showMaxMenuSection = true;
          return;
        }

        // Scrolling up
        if (newValue < preValue && newValue <= this.headerHeight + 150) {
          this.showMaxMenuSection = false;
          this.showDatePicker = true;
          return;
        }
      },
    },
    methods: {
      getImgCdnUrls(thumbnails) {
        return thumbnails.map(
          ({ cdnUrl }) => `${process.env.VUE_APP_CDN}` + "/" + cdnUrl
        );
      },
      handelScroll() {
        if (Date.now() - this.datePickerTimestamp > 20)
          this.scrollPosition = Math.round(window.scrollY);
      },
      resetView() {
        this.headerHeight = 0;
        this.showMaxMenuSection = false;
        window.addEventListener("scroll", this.handelScroll);
      },
      toggleVendorDescription() {
        this.showDescription = !this.showDescription;
      },
    },
    mounted() {
      this.resetView();
      window.addEventListener("resize", () => this.resetView);
      const header = this.$refs["header"];
      if (header) this.headerHeight = header.clientHeight;
    },
    beforeUnmount() {
      window.removeEventListener("scroll", this.handelScroll);
      window.removeEventListener("resize", this.resetView);
    },
  };
</script>

<style lang="scss" scoped>
  header {
    @include outlet-list-header-container;
  }

  .header-image {
    width: 33%;
    z-index: 9;
    max-width: 250px;
    min-width: 150px;

    @include outlet-list-header-image;

    @media screen and (max-width: 600px) {
      max-width: unset;
      padding: 0;
    }

    .mobileLayout & {
      width: 100%;
      position: relative;
      top: unset;
      height: auto;
    }
  }

  .header {
    @include outlet-list-header;
  }

  .details {
    @include outlet-list-details;
  }

  .selector {
    @include outlet-list-selector;
  }

  .operator-name {
    @include view-main-title;

    .mini & {
      padding-right: 5%;
      font-size: 1rem;
    }
  }
</style>
