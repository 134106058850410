<template>
  <button class="day" :class="{ current }" @click="$emit('click', option)">
    <span>{{ formatDate(option.date || option, "dddd") }} </span>
    <h3>{{ formatDate(option.date || option, "dd/MM") }}</h3>
  </button>
</template>
<script>
  export default {
    emits: ["click"],
    props: {
      current: { type: Boolean },
      option: {},
    },
  };
</script>

<style lang="scss" scoped>
  .day {
    @include outlined-button($col: $col_beta-darker, $p: 0.5rem, $h: 2.5rem);

    width: auto;
    flex-direction: row !important;
    gap: 0.25rem;
    transition: 0.2s all;
    min-width: unset;

    h3 {
      font-weight: 500 !important;
    }

    span {
      margin: 0;
      font-size: 0.875rem;
      line-height: 1em;
      font-family: "Stolzl Book";
    }

    &.current {
      @include contained-button(
        $bgCol: $col_beta-darker,
        $textCol: $col_white,
        $p: 0.5rem,
        $h: 2.5rem
      );
    }
    @media only screen and (max-width: $mobile_max_width) {
      height: 2.5rem !important;
    }
    .mobileLayout & {
      span {
        font-size: 0.875rem;
      }
    }
    .mini & {
      width: auto;
      h3,
      span {
        font-size: 0.75rem !important;
      }
    }
  }
</style>
